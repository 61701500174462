/*----------------------------------------*/
/*  19 - Components - Social Link
/*----------------------------------------*/
.uren-social_link {
	padding-top: 25px;
	> ul {
		> li {
			display: inline-block;
			padding-right: 10px;
			&:last-child {
				padding-right: 0;
			}
			> a {
				color: $bg-white_color;
				border-radius: 100%;
				font-size: 16px;
				display: block;
				width: 40px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				@include breakpoint (xx-small) {
					width: 35px;
					height: 35px;
					line-height: 35px;
				}
			}
			&:hover{
				> a{
					background-color: $primary-color !important;
					color: $bg-white_color !important;
				}
			}
			&.facebook{
				> a{
					background-color: $facebook-color;
				}
			}
			&.twitter{
				> a{
					background-color: $twitter-color;
				}
			}
			&.google-plus{
				> a{
					background-color: $google-plus-color;
				}
			}
			&.instagram{
				> a{
					background-color: $instagram-color;
				}
			}
			&.youtube{
				> a{
					background-color: $youtube-color;
				}
			}
		}
	}
}
