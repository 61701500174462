/*----------------------------------------*/
/*  16 - Components - Shipping
/*----------------------------------------*/
.uren-shipping_area {
	padding-top: 80px;
	.shipping-nav {
		border: 1px solid $primary-color;
		padding: 30px 0 25px;
		@include breakpoint(max-medium) {
			padding: 30px 30px 25px;
		}
		.shipping-grid{
			flex: 0 0 20%;
			max-width: 20%;
			@include breakpoint(max-x_large){
				flex: 0 0 50%;
				max-width: 50%;
			}
			@include breakpoint(max-small){
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
		[class*="shipping-grid"]{
			&:last-child{
				@include breakpoint(max-x_large){
					flex: 0 0 100%;
					max-width: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					.shipping-item{
						width: 100%;
						justify-content: center;
						text-align: center;
					}
				}
			}
		}
		.shipping-item {
			display: flex;
			@include breakpoint(max-x_large){
				justify-content: center;
			}
			@include breakpoint(max-small) {
				display: block;
				text-align: center;
			}
			.shipping-icon {
				color: $primary-color;
				font-size: 40px;
				border-radius: 10px;
				width: 60px;
				height: 60px;
				line-height: 58px;
				display: block;
				text-align: center;
				@include breakpoint(max-x_large){
					.shipping-icon{
						width: auto;
					}
				}
				@include breakpoint(normal) {
					width: 50px;
					height: 50px;
					line-height: 48px;
					font-size: 30px;
				}
				@include breakpoint(max-small){
					width: auto;
				}
			}
			.shipping-content {
				border-right: 1px solid $border-color;
				width: calc(100% - 100px);
				padding-left: 15px;
				@include breakpoint(max-x_large){
					border-right: 0;
					width: auto;
					text-align: left;
					padding-bottom: 20px;
				}
				@include breakpoint(max-small){
					text-align: center;
				}
				> h6 {
					margin-bottom: 0;
					padding-bottom: 10px;
				}
				> p {
					margin-bottom: 0;
					font-size: 14px;
					@include breakpoint(normal) {
						font-size: 13px;
					}
				}
				&.last-child{
					border-right: 0;
					@include breakpoint(max-x_large){
						padding-bottom: 0;
					}
				}
			}
		}
	}
}