/*----------------------------------------*/
/*  17 - Components - Product Tab
/*----------------------------------------*/
/* ---Uren's Product Tab Area--- */
.sp-product-tab_area {
	background-color: $bg-white_color;
	padding: 0 0 60px;
	.short-desc {
		margin: 0 auto;
		text-align: center;
		> p {
			margin-bottom: 0;
			padding-bottom: 15px;
		}
	}
	.product-tab {
		border-bottom: 1px solid $border-color;
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
		padding-bottom: 25px;
		@include breakpoint (max-small) {
			display: block;
		}
		.uren-tab_title {
			@include breakpoint (max-small) {
				padding-bottom: 20px;
			}
			> h4 {
				text-transform: uppercase;
				margin-bottom: 0;
				@include breakpoint (max-medium) {
					font-size: 20px;
				}
			}
		}
		> ul {
			&.product-menu {
				@include breakpoint(xx-small){
					display: block;
					width: 100%;
					text-align: center;
				}
				> li {
					> a {
						font-weight: $heading-font_weight;
						text-transform: uppercase;
						padding: 0 30px;
						position: relative;
						display: block;
						@include breakpoint (max-medium) {
							padding: 0 15px;
							font-size: 14px;
						}
						@include breakpoint(xx-small){
							padding: 0 0 10px 0;
						}
						&.active{
							color: $primary-color;
						}
						&:after {
							background-color: $very-light-grey;
							content: "";
							position: absolute;
							top: 50%;
							right: -9px;
							height: 2px;
							width: 18px;
							@include breakpoint (max-medium) {
								width: 10px;
								right: -5px;
							}
							@include breakpoint (max-x_small) {
								display: none;
							}
						}
					}
					&:last-child {
						> a {
							padding-right: 0;
							@include breakpoint(xx-small){
								padding: 0;
							}
							&:after {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
