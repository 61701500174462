//--- All Variables - Related Stuff Included In This Area ---↓

//--- Typography Section Variables ---↓

// Color
$primary-color: #ffc400;
$primary-color-2: #be8658;
$primary-color-3: #ae307c;
$primary-color-4: #58d173;
$body-text_color: #999999;
$heading-color: #333333;
$border-color: #e5e5e5;
$dim-gray_color: #666666;
$bg-white_color: #ffffff;
$bg-alizarin_color: #ea3a3c;
$white-smoke_color: #f4f4f4;
$bg-whisper_color: #ebebeb;
$bg-silver_color: #bbbbbb;
$bg-eclipse_color: #393939;
$bg-nero_color: #222222;
$bg-yellow_color: #fff000;
$bg-cinnabar_color: #d64444;
$bg-wedgewood_color: #516a7e;
$bg-cello_color: #344b5d;
$bg-red_color: #ff0000;
$bg-orange-red_color: #ff4000;
$bg-silver_color: #bababa;
$bg-dark-gray_color: #abaaaa;
$input-placeholder_color: #888888;
$border-rgba_color: rgba(255, 255, 255, .2);
$border-rgba_color-2: rgba(0, 0, 0, .07);
$very-light-grey: #cccccc;
$burnt-orange_color: #ff832b;
$brown_color: #a6311f;
$raw-umber_color: #824900;
$bg-black_color: #000;
$bg-carlet_color: #e52e06;
$bg-pacific-blue_color: #0886cf;
$bg-sapphire_color: #0c2a5c;

// Font Size
$body-font_size: 14px;
$heading-font_size: 24px;
$uren-border_radius: 5px;

// Font Line Height
$line-height: 24px;
$heading-line_height: 1;

// Font Weight
$body-font_weight: 400;
$heading-font_weight: 700;

// Font Family
$body-font: 'Work Sans', sans-serif;
$heading-font: 'Work Sans', sans-serif;

//----Social Link Color Variables ----↓
$facebook-color: #3b579d;
$twitter-color: #3acaff;
$google-plus-color: #dd4b39;
$linkedin-color: #0097bd;
$pinterest-color: #cb2027;
$instagram-color: #833ab4;
$skype-color: #00aff0;
$share-color: #fe6d4c;
$youtube-color: #d72525;

// Color Variation ----↓
$color-1: $primary-color;
$color-2: $primary-color-2;
$color-3: $primary-color-3;
$color-4: $primary-color-4;
$colorList: $color-1, $color-2, $color-3, $color-4;
