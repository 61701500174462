/*----------------------------------------*/
/*  18 - Components - Form
/*----------------------------------------*/
/* ---Uren's Newsletter Form--- */
.newsletter-form_wrap {
	padding-top: 55px;
	width: 770px;
	@include breakpoint(max-medium){
		width: 100%;
	}

	.subscribe-form {
		position: relative;
		@include breakpoint(max-x_small){
			display: flex;
			width: 100%;
			flex-direction: column;
			align-items: center;
		}
		.newsletter-input {
			border: 1px solid $border-color;
			height: 50px;
			width: 100%;
			padding: 0 210px 0 20px;
			@include breakpoint(max-x_small){
				padding: 0 20px;
				margin-bottom: 30px;
			}
		}
		.newsletter-btn {
			font-weight: $heading-font_weight;
			background-color: $primary-color;
			text-transform: uppercase;
			position: absolute;
			font-size: 16px;
			top: 0;
			right: 0;
			width: 190px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			@extend %basetransition;
			@include breakpoint(max-x_small){
				position: relative;
			}
			&:hover {
				background-color: $bg-carlet_color;
				color: $bg-white_color;
			}
		}
	}
}
